import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// ����reset.css
import './assets/css/reset.css'
import scroll from 'vue-seamless-scroll'
// import XLSX from 'xlsx';
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)
import headerCom from '@/components/headerCom.vue'
import orderlistDetails from '@/components/orderlist/orderlistDetails.vue'
Vue.component("header-com",headerCom)
Vue.component("orderlist-details",orderlistDetails)
// 引入自定义插件
import myBtn from '@/plugin/myBtn.js'
// 引入插件样式
import '@/plugin/myBtn.css'
Vue.use(myBtn)
// pdf
import htmlToPdf from '@/utils/htmlToPdf'
Vue.use(htmlToPdf)
import myNoticebar from '@/plugin/myNoticebar'
// 引入插件样式
import '@/plugin/myNoticebar.css'
Vue.use(myNoticebar)

//自定义组件
import Mation from '@/information/index.js'
Vue.use(Mation)

//百度地图插件---tong
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
	ak: 'KP5pEqSMIFqspSo39hSssl9UGr9og2Tg'
})

Vue.use(scroll)

// import './mock/index.js';
import axios from 'axios';
Vue.prototype.$axios = axios;
axios.interceptors.request.use(function(com){
  com.headers.Authorization = "Bearer "+localStorage.getItem('token');
  return com
})

//导入懒加载组件
import {
	Lazyload
} from 'vant';

Vue.use(Lazyload);




Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
