<template>
  <div class="home">
    <!-- 待处理 -->
    <div class="back-login">
      <span>{{ username }}，欢迎您</span>

      <span class="color-back" @click="logout">Log out<img src="@/assets/exit.png" alt=""></span>
    </div>
  </div>
</template>

<style scoped="scoped">
/* 退出 */
.back-login {
  position: fixed;
  top: 0px;
  right: 10px;
  height: 60px;
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.back-login>span:nth-of-type(1) {
  /* width: 0px; */
  height: 100%;
  display: flex;
  color: #3f78f6;
  margin-right: 15px;
  padding: 0 6px 3px 6px;
  /* padding-bottom: 3px; */
  justify-content: center;
  align-items: center;
  background-color: rgba(235, 241, 254);
}

.back-login>span:nth-of-type(1)>img {
  width: 28%;
}

.back-login>* {
  margin: 0 3px;
}

.color-back {
  color: #477ef6;
}

.title-cont {
  margin: 10px 0;
  position: relative;
  height: 36px;
  line-height: 36px;
  font-size: 22px;
  padding-left: 10px;
}

.title-cont::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 3px;
  height: 20px;
  margin-top: -10px;
  background-color: #3f78f6;
}

.wait-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px 0;
}

.wait-content>div {
  width: 384px;
  display: flex;
}

.wait-content>div>p:nth-of-type(1) {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #eaedf4;
}

.wait-content>div>p:nth-of-type(2) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  font-size: 38px;
}

.wait-content>div>p:nth-of-type(2)>span:nth-of-type(1) {
  font-size: 16px;
}

.content-second {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.content-second>div:nth-of-type(2n-1) {
  margin-right: 40px;
}

.move-money {
  width: 804px;
}

.move-content {
  width: 100%;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.bg-box {
  position: relative;
}

.bg-box>p {
  position: absolute;
  top: 20px;
  left: 80px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: #fff;
}

.bg-box>p>span:nth-of-type(1) {
  font-size: 38px;
  margin-bottom: 8px;
}

.content-bg-gray {
  align-items: center;
  justify-content: space-around;
  height: 140px;
  border-radius: 10px;
  background-color: #eaedf4;
}

.content-bg-gray>p {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-bg-gray>p>span:nth-of-type(1) {
  font-size: 38px;
}

.content-bg-gray>p>span:nth-of-type(2) {
  color: #666666;
}

.mgrin-calc {
  margin-top: 10px;
}

.header,
.header>div {
  display: flex;
  justify-content: end;
  align-items: center;
}

.header>p {
  margin-right: 10px;
}

.user_name {
  font-size: 14px;
}

.user_name span {
  color: #ff0000;
  text-decoration: underline;
}

.logout {
  cursor: pointer;
}
</style>
<script>
import $router from "@/router/index.js";
export default {
  filters: {
    // 返回新的数据格式
    date(val) {
      var d = new Date(val);
      return d.toLocaleString();
    },
  },
  // computed: {
  computed: {
    // openTab() {
    //   return this.$store.state.openTab;
    // }
  },
  // },
  methods: {
    logout() {

      var exit = confirm("确定要退出吗");
      if (exit) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        this.user = {};
        const openTab = this.$store.state.openTab;
        const num = this.$store.state.openTab.length;
        // console.log(this.$store.state.openTab);
        for (let index = 0; index < num; index++) {

          const element = openTab[0].route;

          this.$store.commit("delete_tabs", element);
        }

        $router.push("/login");
        //   }, 1000);
      } else {
        return false;
      }
    },
  },

  data() {
    return {
      // 用户总览
      userAll: [],
      username: localStorage.getItem('user'),
      value: "",
      user: JSON.parse(localStorage.getItem("userInfo") || "{}"),


    };
  },
};
</script>
