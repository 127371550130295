export default {
    install(app, option) {
        // console.log(app, option)
        app.component("myButton", {
            template: '<button :class="btn"><slot/></button>',
            props: {
                type: {
                    type: String,
                    default: 'default'
                }
            },
            computed: {
                btn() {
                    return `btn ${this.type}`
                }
            }
        })
    }
}