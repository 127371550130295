import Vue from 'vue'
import VueRouter from 'vue-router'
const originalPush = VueRouter.prototype.push
import {
	Message
} from 'element-ui';

VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'home',
		// component: HomeView
		redirect: '/homes'
	},
	{
		path: '/login',
		name: 'login',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import('../views/LoginView.vue')
	},
	{
		path: '/admin',
		name: 'admin',
		children: [{
				path: '/homes',
				name: 'Home',
				component: () => import('../views/homepage/HomeView.vue'),
				meta: {
					requireAuth: true,
				}
			},
			{
				path: '/sell',
				name: 'Sell',
				component: () => import('../views/sellpage/SellView.vue'),
				meta: {
					requireAuth: true,
					roles:['admin']
				}
			},
			{
				path: '/client',
				name: 'Client',
				component: () => import('../views/clientpage/clientView.vue'),
				meta: {
					requireAuth: true,
					roles:['admin']
				}
			},
			{
				path: '/commodityone',
				name: 'System Purchase',
				component: () => import('../views/commodity/CommodityOne.vue'),
				meta: {
					requireAuth: true
				}
			},
			{
				path: '/commoditythree',
				name: 'Spare Parts Purchase',
				component: () => import('../views/commodity/CommodityThree.vue'),
				meta: {
					requireAuth: true,
				}
			},
			{
				path: '/commoditytwo',
				name: 'Product Library',
				component: () => import('../views/commodity/CommodityTwo.vue'),
				meta: {
					requireAuth: true,
					roles:['admin']

				}
			},
			{
				path: '/commodityfour',
				name: 'Upload Product',
				component: () => import('../views/commodity/CommodityFour.vue'),
				meta: {
					requireAuth: true,
					roles:['admin']

				}
			},{
				path: '/transactionone',
				name: 'Order List',
				component: () => import('../views/transaction/TransactionOne.vue'),
				meta: {
					requireAuth: true,
					roles:['admin']

				}
			}, {
				path: '/transactionthree',
				name: 'Order Details',
				component: () => import('../views/transaction/TransactionThree.vue')
			}, {
				path: '/userone',
				name: 'User Management',
				component: () => import('../views/user/UserOne.vue'),
				meta: {
					requireAuth: true,
					roles:['admin']
				}
			}
		],
		component: () => import('../views/admin/AdminView.vue'),
		meta: {
			requireAuth: true,
		}
	},
	//404的path为* 配置在最后面
	{
		path: '*',
		name: 'nomatch',
		component: () => import( /* webpackChunkName: "about" */ '../views/NoMatch.vue')
	},
]



const router = new VueRouter({
	mode: 'hash',
  // mode: 'history',
  // mode:process.env.NODE_ENV==='production'?'hash':'history',
  base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	// console.log(to);
	const roles=localStorage.getItem('level')
	// if (to.meta.requireAuth) {
	// 	// 如果需要权限,判断
	// 	if (localStorage.getItem("user")) {
	// 		// 如果有token放行
	// 		next(true);
	// 	} 
	// 	else {
	// 		Message({
	// 			message: '请先登录',
	// 			type: 'warning'
	// 		});
	// 		next("/login")
	// 	}
	// } else {
	// 	// 直接放行
	// 	next(true);
	// }
	if (to.meta.roles && !to.meta.roles.includes(roles)) { // 没有权限，跳转到 403 页面
		next({ path: '*' })} else { // 具备相应权限，进入页面
		next()}
})

export default router
