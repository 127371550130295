<template>
	<div class="bjpdf">
		<div class="outputbtn" >
			<!-- <el-button type="primary" @click="printPDF">Export to PDF</el-button> -->
			<el-button type="primary" @click="getPdf()">Export to PDF</el-button>
		<el-button type="primary" @click="download()" >Export to Excel</el-button>
		</div>
		
		<div id="pdfDom" ref="wrap" class="pdfDom">


			<!-- pdf第一页 -->
			<div class="pdf-1">
				<!-- 第一部分表格>>>logo+报价名称 -->
				<table border="1" cellspacing="0" cellpadding="0">
					<tr>
						<td><img src="@/assets/brandLogo.png" alt="" style="height:40px"></td>
					</tr>
					<tr>
						<td>
							<p class="specColor largefont">QUOTATION OF THE BAS UPGRADE FOR MBZ TERMINAL</p>
							<p class="largefont">MBZ靠泊辅助监控系统升级报价文件</p>
						</td>
					</tr>
				</table>
				<!-- 第二个表格 项目详情 -->
				<!-- <table border="0" cellspacing="0" cellpadding="0" class="specTable"> -->
					<!-- <tr>
						<td colspan="3" style="height:90px">
							<div class="speclogo">
								<img src="@/assets/logo.png" alt="">
								<div>
									<p style="font-size:22px">中海壳牌石油化工有限公司</p>
									<p>CNOOC and Shell Petrochemicals Company Limited</p>
								</div>
								<img src="@/assets/logo.png" alt="">
							</div>

						</td>
					</tr>
					<tr>
						<td style="text-align:left">
							<p>MOC NO./WBS Code</p>
							<p>(MOC 项目号/WBS 号)</p>
						</td>
						<td style="color:#23375a" colspan="2">
							PC-MFOM-0750-1004
						</td>
					</tr>
					<tr>
						<td style="text-align:left">
							<p>Project Description</p>
							<p>(项目名称)</p>
						</td>
						<td style="color:#23375a" colspan="2">
							UPGRADE MARINE & METEOROLOGY SYSTEM OF MBZ
						</td>
					</tr>
					<tr>
						<td colspan="2" style="text-align:left">
							<p>Supplier/Contractor Doc No: <span class="specColor">YQ-160812-QUO-V21</span> </p>
							<p>(厂家文件号)</p>
						</td>
						<td style="text-align:left">
							<p>Supplier/Contractor Rev:</p>
							<p>(供应商版次) V21</p>
						</td>
					</tr> -->
				<!-- </table> -->
				<!-- 第三个表格  其他 -->
				<!-- <table border="0" cellspacing="0"> -->
					<!-- <tr>
						<td style="width:200px">
							<p>SUPPLIER/Contractor</p>
							<p>(供应商/承包商)</p>
						</td>
						<td>
							<p class="specColor">Shanghai Coneall Technology & Engineering Co., Ltd</p>
							<p>上海永乾科技工程有限公司</p>
						</td>
					</tr>
					<tr>
						<td style="width:200px">
							<p>VALIDITY</p>
							<p>(有效期)</p>
						</td>
						<td class="specColor">
							<p style="font-size:12px">THIS QUOTATION WILL BE VALID FOR 30 DAYS FROM THIS DATE.</p>
							<p>有效期 15 天</p>
						</td>
					</tr>
					<tr>
						<td style="width:200px">
							<p>DATE (DAY-MONTH-YEAR)</p>
							<p>(日期)</p>
						</td>
						<td>
							<p class=" specColor">{{ tempOrder[0].createdAt }}</p>
						</td>
					</tr> -->
				<!-- </table> -->
			</div>
			<!-- pdf第二页 -->
			<div class="pdf-2">

				<p class="title">Quote Details</p>
				
			<div id ="filler1"></div>
				<div>
					<el-table :data="item.itemsModels" v-for="item in tempOrder.listItem" :key="item.systemModel.id" class="tabledetail ">
						<el-table-column :label="item.systemModel.name" align="center" class="small">
							<el-table-column prop="deviceId" label="Serial Number" width="150" ></el-table-column>
							<el-table-column prop="deviceName" label="Product" width="200" ></el-table-column>
							<el-table-column prop="sysName" label="Type"></el-table-column>
							<el-table-column prop="num" label="Quantity"></el-table-column>
							<el-table-column prop="price" label="Price(CNY)" ></el-table-column>
							<el-table-column prop="tax" label="Tax(CNY)" ></el-table-column>
							<el-table-column prop="totalPrice" label="Total price(CNY)" ></el-table-column>
						</el-table-column>
					</el-table>
				</div>

			
			</div>
		</div>
	</div>
</template>

<style>
/* * {
	margin: 0;
	padding: 0;

} */
.pdfDom{
	width: 992px;
	margin: 0 auto;
	/* height: 850px; */
	/* height: 100%; */
}
.pdf-1 {
	padding-top: 500px;
		height: 1410px;
		

	/* margin-bottom: 200px; */
	/* background-color: #999; */
}

.pdf-2 {
	margin: 40px 40px 40px 40px;
		/* height: 1410px; */

	margin-bottom: 10px;
	/* background-color: #456; */

}

.pdf-1 table {
	width: 830px;
	margin: 0 auto;
	margin-bottom: 80px;
	border-color: #747474;
}

.pdf-1 table tr td {
	color: #000;
	padding: 8px 8px;
	text-align: center;
}

.pdf-1 table .specColor {
	color: #23375a;
	font-weight: bold;
}

.pdf-1 table td p {
	font-weight: bold;
	/* font-size: 14px; */
	margin: 20px 0px;
	font-family: Times New Roman;
}

.largefont {
	font-size: 18px;
	font-weight: bold;

}

.pdf-2 .tabledetail {
	margin-bottom: 50px;
}

.speclogo {
	display: flex;
	justify-content: space-between;
	padding: 0 20px;
}

.speclogo img {
	width: 45px;

}

.specTable td {
	width: 33%;

}

.title {
	/* padding-top: 200px; */
	margin-bottom: 20px;
	font-size: 20px;
	font-weight: bold;
	color: #000;
}
</style>

<script>
// 导入订单接口
import {
	downloadOrder,getQuotation
} from '@/api/orderlist/orderlist.js'
import html2canvas from 'html2canvas'
 import jsPDF  from 'jspdf'
import fileDownload from 'js-file-download'
export default {

	// 管道,过滤
	filters: {
		// 返回新的数据格式
		date(val) {
			var d = new Date(val);
			return d.toLocaleString();
		}
	},
	watch: {
    // 监听路由参数的变化
    '$store.state.orderId': {
      immediate: true, // 初始化时也执行一次
      handler() {
        this.getPdfQuo(); // 数据变化时发起请求
      }
    }
  },
	data() {
		return {
			fileName:'',
			htmlTitle: '',
			dialogTableVisible: false,
			tempOrder: [],
			dialogVisible: false,
			orderStatic: [], //订单统计数据
			search: {
				status: 'All',
				orderNum: '',
				shoppingAdd: '',
				createStart: ''
			}, //搜索条件
			orderlist: [], //订单信息

			
		}
	},
	created() {


	},

	methods: {
		printPDF(){
			this.$message.warning('正在处理中，请稍后')
            // report ref
            let PDFView = this.$refs.wrap   //注意这里获取到ref的节点
            // 获取分割dom，此处为class类名为item的dom
            let lableListID = document.getElementsByClassName('small')
            console.log(PDFView, 'PDFView')
		// 	let imgWidth = 595.28;
        // let pageHeight = (592.28 / contentWidth) * contentHeight;
            let pageHeight = (PDFView.scrollWidth /  595.28) *841.89 //
            // 进行分割操作，当dom内容已超出a4的高度，则将该dom前插入一个空dom，把他挤下去，分割
            console.log(lableListID.length, 'lableListID')
            for (let i = 0; i < lableListID.length; i++) {
                let multiple = Math.ceil(
                    (lableListID[i].offsetTop + lableListID[i].offsetHeight) /
                        pageHeight
                )
                console.log(multiple, 'multiple')
                if (this.isSplit(lableListID, i, multiple * pageHeight)) {
                    let divParent = lableListID[i].parentNode // 获取该div的父节点
                    let newNode = document.createElement('div')
                    newNode.className = 'emptyDiv'
                    newNode.style.background = '#ffffff'
                    let _H =
                        multiple * pageHeight -
                        (lableListID[i].offsetTop + lableListID[i].offsetHeight)
                    newNode.style.height = _H + 30 + 'px'
                    newNode.style.width = '100%'
                    let next = lableListID[i].nextSibling // 获取div的下一个兄弟节点
                    // 判断兄弟节点是否存在
                    // console.log(next);
                    if (next) {
                        // 存在则将新节点插入到div的下一个兄弟节点之前，即div之后
                        divParent.insertBefore(newNode, next)
                    } else {
                        // 不存在则直接添加到最后,appendChild默认添加到divParent的最后
                        divParent.appendChild(newNode)
                    }
                }
            }

			html2canvas(this.$refs.wrap, {
        allowTaint: true,
      }).then(function (canvas) {
        const contentWidth = canvas.width;
        const contentHeight = canvas.height;
        //一页pdf显示html页面生成的canvas高度;
        let pageHeight = (contentWidth / 592.28) * 841.89;
        //未生成pdf的html页面高度
        let leftHeight = contentHeight;
        //页面偏移
        let position = 0;
        //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
        let imgWidth = 595.28;
        let imgHeight = (592.28 / contentWidth) * contentHeight;
        let pageData = canvas.toDataURL("image/jpeg", 1.0);
        const PDF = new jsPDF("", "pt", "a4");
		
        //有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
        //当内容未超过pdf一页显示的范围，无需分页
        if (leftHeight < pageHeight) {
          PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
        } else {
          while (leftHeight > 0) {
            PDF.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
            leftHeight -= pageHeight;
            position -= 841.89;
			
            //避免添加空白页
            if (leftHeight > 0) {
              PDF.addPage();
            }
          }
        }
 
        // const pageData = canvas.toDataURL("image/jpeg", 1.0);
        // //p表示纵向，l表示横向，px是单位 ,[宽，长]->[x,y]
        // const PDF = new JsPdf("p", "px", [2550, 3100]);
        // PDF.addImage(pageData, "JPEG", 0, 0, 2550, 3100);
        PDF.save("Product Quotation Sheet .pdf");
      });
		},
		print() {
      // 当下载pdf时，若不在页面顶部会造成PDF样式不对,所以先回到页面顶部再下载
      let top = this.$refs.wrap;
      console.log(top);
      if (top != null) {
        top.scrollIntoView();
        top = null;
      }
	

      html2canvas(this.$refs.wrap, {
        allowTaint: true,
      }).then(function (canvas) {
        const contentWidth = canvas.width;
        const contentHeight = canvas.height;
        //一页pdf显示html页面生成的canvas高度;
        let pageHeight = (contentWidth / 592.28) * 841.89;
        //未生成pdf的html页面高度
        let leftHeight = contentHeight;
        //页面偏移
        let position = 0;
        //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
        let imgWidth = 595.28;
        let imgHeight = (592.28 / contentWidth) * contentHeight;
        let pageData = canvas.toDataURL("image/jpeg", 1.0);
        const PDF = new jsPDF("", "pt", "a4");
		
        //有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
        //当内容未超过pdf一页显示的范围，无需分页
        if (leftHeight < pageHeight) {
          PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
        } else {
          while (leftHeight > 0) {
            PDF.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
            leftHeight -= pageHeight;
            position -= 841.89;
			
            //避免添加空白页
            if (leftHeight > 0) {
              PDF.addPage();
            }
          }
        }
 
        // const pageData = canvas.toDataURL("image/jpeg", 1.0);
        // //p表示纵向，l表示横向，px是单位 ,[宽，长]->[x,y]
        // const PDF = new JsPdf("p", "px", [2550, 3100]);
        // PDF.addImage(pageData, "JPEG", 0, 0, 2550, 3100);
        PDF.save("Product Quotation Sheet .pdf");
      });
    },
	/**
	* @description: 获取底部元素
	*/
		getFooterElement(remainingHeight, fillingHeight = 85) {
			let newNode = document.createElement('div')
			newNode.style.background = '#ffffff'
			newNode.style.width = 'calc(100% + 8px)'
			newNode.style.marginLeft = '-4px'
			newNode.style.marginBottom = '0px'
			newNode.style.borderTop = '1px solid #7F7F7F'
			newNode.style.height = (remainingHeight + fillingHeight) + 'px' // pdf截断需要一个空白位置
			return newNode
		},
		// 获取报价数据
		getPdfQuo(){
			let data=this.$store.state.orderId
			console.log(data,'弹框里面的数据');
			getQuotation(data).then(res=>{
				this.tempOrder=res.data
				console.log(res,"获取到的订单");
			})
		},
		download2(){
			window.open(
        '172.16.23.160:9002/order/download?orderNum=CNL202303031081276533010599936',
        // '_target'
    );
		},
		// 下载excel
		download(){
	
			let data=this.$store.state.orderId
			downloadOrder(data).then(result => {
                    console.log(result)
                    const link = document.createElement('a')  //创建a标签
                    const blob = new Blob([result.data], { type: 'application/vnd.ms-excel' }) //设置文件流
                    link.style.display = 'none'
                    // 设置连接
                    link.href = URL.createObjectURL(blob)  //将文件流转化为blob地址
                    link.download = '报价单'
                    document.body.appendChild(link)
                    // 模拟点击事件
                    link.click()  //设置点击事件
                })
                .catch(err => {
                    console.log(err)
                })



      
		},
		isSplit(nodes, index, pageHeight) {
                 return !!(
                nodes[index].offsetTop + nodes[index].offsetHeight <
                    pageHeight &&
                nodes[index + 1] &&
                nodes[index + 1].offsetTop + nodes[index + 1].offsetHeight >
                    pageHeight
            )
        },
		getSmall() {
			let dom = document.getElementsByClassName("small");
			console.log('改变了',dom);

			for (var i = 0; i < dom.length; i++) {
				if (
					this.offset(dom[i]).top % 850 > this.offset(dom[i + 1]).top % 850 &&
					850 - (this.offset(dom[i]).top % 850) < dom[i].offsetHeight
				) {
					dom[i - 1].style.marginBottom =
						900- (this.offset(dom[i]).top % 850) + "Px";
				}
			}
			
		},
		offset(element) {

			var pos = { left: 0, top: 0 };

			var parents = element.offsetParent;

			pos.left += element.offsetLeft;

			pos.top += element.offsetTop;

			while (parents && !/html|body/i.test(parents.tagName)) {

				pos.left += parents.offsetLeft;

				pos.top += parents.offsetTop;

				parents = parents.offsetParent;

			}

			return pos;

		},
		// 导出数据为excel
		exportToExcel() {
			this.dialogTableVisible = true;
			console.log(this.tempOrder[0].orderlist);
		},

		//分页组件
		handleSizeChange(val) {  
			this.pageSize = val; //动态改变
			this.opdata.size = val;
			this.getOpData();
			this.pageTotal = this.pagnation.total / this.pagnation.size;
		},
		handleCurrentChange(val) {
			this.currentPage = val; //动态改变
			this.opdata.current = val;
			this.getOpData();
		},




		isShow(data) {
			this.dialogVisible = true,
				this.detailContent = data
			console.log(this.detailContent)
			// {{detailContent}}
		},
		handleClose(done) {
			this.$confirm('Are you sure you want to close?')
				.then(() => {
					done();
				})
				.catch(() => { });
		},



	},
	mounted() {
		this.$nextTick(() => {
			this.getSmall()
		})
		this.getPdfQuo()
	},
	
}
</script>

<style>
.outputbtn{
	display: flex;
	justify-content: space-between;
	padding: 0 80px;
}
</style>
