import { getQuotation } from "@/api/orderlist/orderlist.js";
export default {
    state: {
        orderStatic: '',
        loading: true,
        loadings: true,
    },
    mutations: {
      
        SET_STATIC(state, data) {
            state.orderStatic = data;
			// 静态列表存储本地---tong
			localStorage.setItem('orderStatic',JSON.stringify(data))
        }

    },
    actions: {
        // 获取订单详情
        Getorder(context,data) {
            getQuotation(data)
                .then(res => {
                    context.commit('SET_STATIC', res.data)
                    // console.log(res,'store获取到的',context.state.orderStatic);
                })
        },
      
    }
}