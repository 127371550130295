import { GetRoutes } from "@/api/routes/routes.js";
import $router from "@/router/index.js"
export default{
    state:{
        routes:[]
    },
    mutations:{
        SET_ROUTES(state,data){
            state.routes=data
        }
    },
    actions:{
        getRoutes(context){
            GetRoutes()
            .then(res=>{
                var admin = $router.options.routes[2];
                var routes = res.data.data.map(item=>{
                    return {
                        path:'/'+item.path,
                        name:item.name,
                        component:()=>import('@/views/routes/'+item.component+'.vue')
                    }
                })
                admin.children.push(...routes)
                $router.addRoute(admin)
                context.commit("SET_ROUTES",res.data.data)
            })
        }
    }
}