export default {
    install(app, option) {
        // console.log(app, option)
        app.component("myNoticebar", {
            template: '<div :class="noticebar" v-show="isShow"><p :class="text"><slot/></p><span @click="hidden()">X</span></div>',
            props: {
                type: {
                    type: String,
                    default: 'default'
                }
            },
            data() {
                return {
                    isShow: true,
                };
            },
            methods: {
                hidden(){
                    this.isShow=false
                    // console.log('13')
                }
            },
            computed: {
                noticebar() {
                    return `noticebar ${this.type}`
                },
                text() {
                    return `text`
                }
            }
        })
    }
}