import Vue from 'vue'
import Vuex from 'vuex'
import user from "./user/user.js"
import orderlist from "./orderlist/orderlist.js"
import routes from "./routes/routes.js"
import store from "./store/store.js"
// 菜单管理
import permission from "./user/permission.js"
// 商品模块---tong
// import goods from './goods/goods.js'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    openTab: [],//所有打开的路由
    activeIndex: '/homes', //激活状态
    orderId:''

  },
  mutations: {
    SETORDERID(state,data){
      state.orderId = data
    },
    // 添加tabs
    add_tabs(state, data) {
      state.openTab.push(data);
    },
    // 删除tabs
    delete_tabs(state, route) {
      let index = 0;
      for (let option of state.openTab) {
        if (option.route === route) {
          break;
        }
        index++;
      }
      state.openTab.splice(index, 1);
    },
    // 设置当前激活的tab
    set_active_index(state, index) {
      state.activeIndex = index;
    },
  },
  actions: {
  },
  modules: {
    user, orderlist, store, routes,  permission
  }
})
