import request from "@/utils/request";
// import qs from 'qs'


// 获取所有报价
function getQuotationAll() {
    return request.get('/api/quo/getQuoOrder')
}

// 发送报价
function sendQuotation(data) {
    return request.post('/api/order',data)
}

// 获取报价
function getQuotation(data) {
    return request.get('/api/order?number='+ data)
}
// 下载excel
// function downloadOrder(data) {
//     return request.get('/api/order/download?orderNum='+data,{
//         // responseType: 'blob' //返回的数据类型
//         responseType: 'blob',
//         headers: {
//         'Content-Type': 'application/json'
//         } //返回的数据类型
//     } )
// }
function downloadOrder(data) {
    return request({
        url: '/api/order/download',
        method: 'get',
        responseType: 'blob',  //需要在此处设置请求头，设置请求的类型为blob文件流的形式
        params: {
            orderNum: data
        }
    })
  }

// 获取所有订单
function getOrderlistAll() {
    return request.get('/api/order/getOrders')
}
// 查询订单
function searchOrder(data) {
    return request.get('/api/order/search?orderNum='+ data)
}
export { downloadOrder,getQuotation,getQuotationAll,sendQuotation,getOrderlistAll,searchOrder}