import $router from "@/router/index.js";
import { Getlogin } from "@/api/login/user.js";
// import { cdddity } from "@/api/commo/Add.js";
import { Message } from 'element-ui';
export default {
    state: {
        user: {
            username: "",
            password: "",
        },
        token: "",
        type: '',
        level:''
    },
    mutations: {
        // 存储token
        SET_TOKEN(state, data) {
            state.token = data;
            localStorage.setItem("token", data);
        },
        // 存储user
        SET_USER(state, data) {
            state.user = data;
            localStorage.setItem("user", data);
        },
        // 存储商品 
        SET_TYPE(state, data) {
            state.type = data;
        },
        SET_LEVEL(state,data){
            state.level = data;
            localStorage.setItem("level", data);

        }
    },
    actions: {
        // GetTpye(context,data) {
        //     cdddity(data).then(res => {
        //         context.commit('SET_TYPE', res.data.data)
        //     })
        // },
        // 退出
        logout(context) {
            context.commit("SET_TOKEN", '')
            context.commit("SET_USER", {})
        },
        // 登录
        login(context, data) {
            Getlogin(data)
                .then(res => {
                    
                    if (res.data=='admin'|| res.data=='user') {
                        // console.log(res,'登陆结果');
                        Message({
                            message: 'Login Successful!',
                            type: 'success'
                        });
                        let levelname=res.data;
                        // 存储 token
                        context.commit("SET_TOKEN", res.data.token)
                        // 存储 user
                        context.commit("SET_USER", data.username)
                        context.commit("SET_LEVEL",levelname)
                        $router.replace('/homes')
                        
                        // 路由查询
                        // var $route = $router.history.current;
                        // var redirect = $route.query.redirect || '/'
                    }else{
                        Message({
                            message: 'Login Failed!',
                            type: 'error'
                        });
                    }
                })
                .catch(err => {
                    Message({
                        message: 'Login Failed!',
                        type: 'error'
                    });
                    console.error(err,'原因')
                })
        }
    }
}