<template>
	<div id="app">
		<router-view />
	</div>
</template>
<script>
	export default {
		data() {
			return {
				isOpen: false,
			};
		},
		// 如果vuex里面没有菜单，执行一次
		created() {
			if (!this.$store.state.routes.routes.length) {
				this.$store.dispatch("getRoutes")
			}
			// 获取本地存储的静态订单数据---tong
			this.$store.commit('SET_STATIC', JSON.parse(localStorage.getItem('orderStatic') || '[]'))
			// 获取本地存储的商品静态数据---tong
			this.$store.commit('SET_PRODUCT_STATIC', JSON.parse(localStorage.getItem('productStatic') || '[]'))

		},
		methods: {
			handleOpen(key, keyPath) {
				console.log(key, keyPath);
			},
			handleClose(key, keyPath) {
				console.log(key, keyPath);
			},
		},
	};
</script>
<style>
	#app {
		width: 100vw;
		height: 100vh;
		overflow-x: hidden;
	}

	.fold {
		font-size: 24px;
		margin-left: 0px;
		margin-top: 21px;
	}

	/*菜单关闭*/
	.el-submenu>.el-submenu__title .el-submenu__icon-arrow {
		-webkit-transform: rotateZ(-90deg);
		-ms-transform: rotate(-90deg);
		transform: rotateZ(-90deg);
	}

	/*菜单展开*/
	.el-submenu.is-opened>.el-submenu__title .el-submenu__icon-arrow {
		-webkit-transform: rotateZ(0deg) !important;
		-ms-transform: rotate(0deg) !important;
		transform: rotateZ(0deg) !important;
	}

	* {
		margin: 0;
		padding: 0;
	}

	.aside {
		background-color: #fff;
		height: calc(100vh - 66px);
	}

	.main {
		background-color: #fafafa;
	}

	.footer {
		background-color: gray;
	}

	.container {
		height: calc(100vh - 66px);
	}
</style>
