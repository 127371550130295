// 导入axios
import axios from 'axios'
import Vue from 'vue';

import {
	Toast
} from 'vant';

Vue.use(Toast);
// var toast = null; //定义一个加载提示
// 设置默认请求域名，超时时间
const request = axios.create({
	baseURL: "/", // 默认请求域名
	timeout: 5000, // 请求超时
})

// 添加请求头token

request.interceptors.request.use(function(config) {
	config.headers.Authorization = 'Bearer ' + localStorage.getItem('token');
	return config; // 返回配置
	//如果配置里面有loading 显示加载提示
	// if (config.loading) {
	// 	toast = Toast.loading({
	// 		message: '加载中..',
	// 		forbidClick: true,
	// 	});
	// }
})

// 导出
export default request;
